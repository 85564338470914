$(document).ready(function () {
	/*var hasBeenTrigged = false;
	$('body').scroll(function() {
		if ($(this).scrollTop() == 0){
			$('header').removeClass('scrolled');
			$('html').removeClass('scrolled');
			hasBeenTrigged = false;
		}
		if ($(this).scrollTop() >= 50 && !hasBeenTrigged) {
			$('header').addClass('scrolled');
			$('html').addClass('scrolled');
			hasBeenTrigged = true;
		}
	})*/
});